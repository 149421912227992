// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "T_hZ";
export var businessCustomSoftwareSection = "T_hX";
export var caseStudyCustomSoftwareSection = "T_h1";
export var casesCustomSoftwareSection = "T_h3";
export var clientQuotesCustomSoftwareSection = "T_h9";
export var expertiseCustomSoftwareSection = "T_h5";
export var industriesCustomSoftwareSection = "T_hW";
export var ourAchievementsCustomSoftwareSection = "T_h8";
export var processCustomSoftwareSection = "T_hY";
export var requirementCustomSoftwareSection = "T_h2";
export var servicesCustomSoftwareSection = "T_hV";
export var techStackCustomSoftwareSection = "T_h0";
export var testimonialsCustomSoftwareSection = "T_h6";
export var testimonialsCustomSoftwareSection__testimonial = "T_h7";
export var whyChooseCustomSoftwareSection = "T_h4";